
import Vue from "vue";
import ProjectUser from "@/data/models/api/ProjectUser";

export default Vue.extend({
  name: "user-delete",
  props: {projectId: Number, user: ProjectUser, dialogOpened: Boolean},
  data() {
    return {
      loading: false,
      project: null,
      projectName: ""
    };
  },
  watch: {
    dialogOpened: {
      immediate: true,
      handler: function (isOpen) {
        if (isOpen) {
          if (this.user === null) {
            this.$notify(this.$t("errors.unknown_error").toString(), {color: "red"});
            this.closeOverlay();
          }
        }
      }
    }
  },
  methods: {
    closeOverlay(): void {
      this.$emit("closeDelete");
    },
    deleteUser(): void {
      this.$service.projects.removeUserFromProject(this.projectId, this.user.userId)
          .catch((error) => this.$notify(this.$t(error).toString(), {color: "red"}))
          .finally(() => this.closeOverlay());
    }
  }
});
