
import Invitation from "@/data/models/api/Invitation";
import Vue from "vue";

export default Vue.extend({
  name: "invitations-button",
  created() {
    this.refreshInvitations();
  },
  data() {
    return {
      timer: null,
      invitations: [],
      dialogOpened: false
    };
  },
  methods: {
    refreshInvitations() {
      this.$service.invitations.getInvitations()
          .then((invitations: Invitation[]) => {
            this.$store.commit("SET_INVITATIONS", invitations);
            this.invitations = invitations;

            if(invitations.length === 0) this.dialogOpened = false;
          });
    },
    acceptInvitation(invitation: Invitation) {
      this.$service.invitations.acceptInvitation(invitation)
          .catch(() => this.$notify(this.$t("errors.unknown_error").toString(), {color: "red"}))
          .finally(() => {
            this.refreshInvitations();
            this.refreshProjects();
          });
    },
    declineInvitation(invitation: Invitation) {
      this.$service.invitations.declineInvitation(invitation)
          .catch(() => this.$notify(this.$t("errors.unknown_error").toString(), {color: "red"}))
          .finally(() => this.refreshInvitations());
    },
    refreshProjects(): void {
      this.$emit("refreshProjects");
    }
  },
  computed: {
    nbOfInvitations() {
      return this.$store.getters.invitations.length;
    }
  }
});
