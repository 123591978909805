
import Vue from "vue";
import ProjectUser from "@/data/models/api/ProjectUser";
import InvitationsButton from "@/components/molecules/buttons/InvitationsButton.vue";

export default Vue.extend({
  name: "header-banner",
  components: {InvitationsButton},
  props: {projectDetail: {type: Boolean, default: false}},
  data() {
    return {
      searchValue: "",
      dialogOpened: false
    };
  },
  computed: {
    appUserName(): ProjectUser {
      return this.$store.getters.appUser?.name ?? "";
    }
  },
  watch: {
    searchValue(value) {
      this.$store.commit("SET_SEARCH_PROJECT", value);
    }
  },
  destroyed() {
    this.$store.commit("SET_SEARCH_PROJECT", "");
  }
});
