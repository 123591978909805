
import Vue from "vue";
import UserManagement from "@/components/molecules/cards/overlay/UserManagement.vue";
import ProjectManagement from "@/components/molecules/cards/overlay/ProjectManagement.vue";
import DeleteProject from "@/components/molecules/cards/overlay/DeleteProject.vue";
import LeaveProject from "@/components/molecules/cards/overlay/LeaveProject.vue";
import LanguageManagement from "@/components/molecules/cards/overlay/LanguageManagement.vue";
import RoleProtection from "@/data/models/roles/RoleProtection";
import Project from "@/data/models/api/Project";

export default Vue.extend({
  name: "project-settings-button",
  components: {UserManagement, ProjectManagement, DeleteProject, LanguageManagement, LeaveProject},
  props: {project: Project, fromStore: Boolean},
  data() {
    return {
      dialogOpenedUser: false,
      dialogOpenedLanguage: false,
      dialogOpenedProject: false,
      dialogOpenedDelete: false,
      dialogOpenedLeave: false,

      currentUserRoleInProject: null as RoleProtection,
      loading: false,
    };
  },
  async created() {
    if(this.fromStore) {
      this.currentUserRoleInProject = this.$store.getters.appUser.roleAbility;
    } else {
      await this.getMe();
    }
  },
  computed: {
    actions(): any[] {
      const ability: RoleProtection = this.currentUserRoleInProject //this.$store.getters.appUser.roleAbility;
      const items = [];

      if (ability.canWriteUser || ability.canWriteInvitation) {
        items.push({
          title: this.$t("dropdown.users"),
          callback: () => this.dialogOpenedUser = true
        });
      }
      if (ability.canWriteLanguage) {
        items.push({
          title: this.$t("dropdown.languages"),
          callback: () => this.dialogOpenedLanguage = true
        });
      }
      if (ability.canWriteProject) {
        items.push({
          title: this.$t("dropdown.project"),
          callback: () => this.dialogOpenedProject = true
        });
      }
      if (ability.canDeleteProject) {
        items.push({
          title: this.$t("dropdown.delete_project"),
          important: true,
          callback: () => this.dialogOpenedDelete = true
        });
      } else {
        items.push({
          title: this.$t("dropdown.leave_project"),
          important: true,
          callback: () => this.dialogOpenedLeave = true
        });
      }

      return items;
    },
    dialogOpened: {
      get(): boolean {
        return this.dialogOpenedUser || this.dialogOpenedLanguage || this.dialogOpenedProject || this.dialogOpenedDelete || this.dialogOpenedLeave;
      },
      set(): void {
        this.dialogOpenedUser= false;
        this.dialogOpenedLanguage= false;
        this.dialogOpenedProject= false;
        this.dialogOpenedDelete= false;
        this.dialogOpenedLeave= false;
      }
    }
  },
  methods: {
    //USED TO RETRIEVE THE ROLE OF THE USER IN THE PROJECT
    getMe() {
      this.loading = true;

      this.$service.user.getMyselfInProject(this.project.id)
          .then((user) => {
            this.currentUserRoleInProject = user.roleAbility;
            this.loading = false;
          });
    },
    projectUpdated(project: Project) {
      if(this.fromStore) {
        this.$store.commit("UPDATE_CURRENT_PROJECT_PARAMETERS", project);
      } else {
        this.$emit("update_projects", project);
      }
    }
  }
});
