
import Vue from "vue";
import {TranslationItem} from "@/data/models/types/TranslationTypes";

export default Vue.extend({
  name: "DeleteKey",
  props: {item: {}, dialogOpened: Boolean},
  data() {
    return {
      loading: false
    };
  },
  methods: {
    closeOverlay(): void {
      this.$emit("close");
    },
    deleteKey() {
      this.loading = true;

      this.$service.keys.deleteKey((this.item as TranslationItem).key.id)
          .then(() => this.$emit("deletedKey"))
          .catch((error) => this.$notify(this.$t(error).toString(), {color: "red"}))
          .finally(() => this.loading = false);
    }
  }
});
