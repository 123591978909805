
import Project from "@/data/models/api/Project";
import { Platform, PlatformExtension, PlatformFileExpected } from "@/data/models/enums/project";
import ImportError from "@/data/models/ImportError";
import ImportItem from "@/data/models/ImportItem";
import { colorRules } from "@/data/rules/ColorRules";
import { importRules, iOSImportRules } from "@/data/rules/ImportRules";
import { languageNameRules } from "@/data/rules/LanguageRules";
import { projectNameRules } from "@/data/rules/ProjectRules";
import Vue from "vue";

export default Vue.extend({
  name: "project-management",
  props: {project: Project, dialogOpened: Boolean},
  data() {
    return {
      fromImport: false,
      selectedPlatform: Platform.ANDROID,
      importItems: [new ImportItem("", null)] as ImportItem[],
      updatedProject: null as Project,
      writtenColor: "",
      languageName: "",
      colors: ["02188C", "EA1B32", "88B618", "FD6922", "17BFDB", "FFCF20", "7F5CE9", "B5B5B5"],
      projectNameRules: projectNameRules(),
      colorRules: colorRules(),
      languageRules: languageNameRules(),
      loading: false,
      importError: null as ImportError
    };
  },
  watch: {
    writtenColor() {
      if (/([A-Fa-f0-9]{6})$/i.test(this.writtenColor) === true) {
        this.updatedProject.color = this.writtenColor;
      }
    },
    dialogOpened: {
      immediate: true,
      handler: function (isOpened) {
        if (isOpened) {
          this.updatedProject = Project.map(this.project ?? {color: this.colors[0]});
          this.writtenColor = this.project?.color ?? this.colors[0];
          this.languageName = "";
        }
      }
    }
  },
  computed: {
    isCreating(): boolean {
      return !this.project;
    },
    extensionLimitation(): string {
      return PlatformExtension(this.selectedPlatform);
    },
    platforms(): { id: number; name: string }[] {
      return Object.values(Platform).map((platform, index) => {
        return {id: index, name: platform};
      });
    },
    expectedFormatExplain(): string[] {
      return PlatformFileExpected(this.selectedPlatform);
    },
    multipleFiles(): boolean {
      return this.selectedPlatform === Platform.IOS;
    },
    fileRules(): any {
      return this.selectedPlatform === Platform.IOS ? iOSImportRules() : importRules();
    }
  },
  methods: {
    actionButton() {
      if (this.isCreating) {
        if (this.fromImport) {
          if ((this.$refs.formChangeSettings as Vue & { validate: () => boolean }).validate() === true) {
            this.loading = true;

            this.$service.projects.importProject(this.updatedProject, this.importItems, this.selectedPlatform)
                .then((id) => {
                  this.loading = false;

                  this.closeManageProject();
                  this.$router.push(`/projects/${id}`);
                  // window && window.location.reload();
                })
                .catch((e: any) => {
                      console.error(e);

                      if (e instanceof ImportError) {
                        this.importError = e;
                      }

                      this.$notify(this.$t("errors.unknown_error").toString(), {color: "red"});
                    }
                )
                .finally(() => {
                  this.loading = false
                });
          }
        } else {
          this.createNewProject();
        }
      } else {
        this.update();
      }
    },
    update() {
      if ((this.$refs.formChangeSettings as Vue & { validate: () => boolean }).validate() === true) {
        this.loading = true;

        this.$service.projects.changeProjectSettings(this.updatedProject)
            .then(() => {
              this.$emit("projectUpdated", this.updatedProject);
              this.closeManageProject();
            })
            .catch((error: any) => this.$notify(this.$t(error).toString(), {color: "red"}))
            .finally(() => this.loading = false);
      }
    },
    createNewProject() {
      if ((this.$refs.formChangeSettings as Vue & { validate: () => boolean }).validate() === true) {
        this.loading = true;
        this.$service.projects.createProject(this.updatedProject, [this.languageName.toLowerCase()])
            .then((project: Project) => {
              this.loading = false;
              this.closeManageProject();

              this.$router.push(`/projects/${project.id}`);
            })
            .catch(() => this.$notify(this.$t("errors.unknown_error").toString(), {color: "red"}))
            .finally(() => this.loading = false);
      }
    },
    closeManageProject() {
      this.$emit("close");
    },
    addImportItem() {
      this.importItems.push(new ImportItem("", null));
    }
  }
});
