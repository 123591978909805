
import Vue from "vue";
import {FileData, TranslationFile} from "@/data/models/types/export";
import {Platform} from "@/data/models/enums/project";
import Export from "@/data/helpers/export";
import store from "@/store";
import {TranslationStatus} from "@/data/models/api/Value";

export default Vue.extend({
    name: "download-project-card",
    props: {
        dialogOpened: Boolean
    },
    data() {
        return {
            isGenerated: false,
            selectedPlatform: Platform.ANDROID as Platform,
            prefixKeysWithGroupName: true,
            downloadOnlyValidatedValues: true,
            files: [] as TranslationFile[]
        };
    },
    watch: {
        selectedPlatform: {
            immediate: true,
            handler: function () {
                this.generateFiles();
            }
        },
        prefixKeysWithGroupName: {
            immediate: true,
            handler: function () {
                this.generateFiles();
            }
        },
        downloadOnlyValidatedValues: {
            immediate: true,
            handler: function () {
                this.generateFiles();
            }
        },
        dialogOpened(isOpened) {
            if (isOpened) {
                //ON RE-OPENED, RESET DATA
                this.files = [];
                this.isGenerated = false;
                this.selectedPlatform = Platform.ANDROID;

                this.generateFiles();
            }
        }
    },
    computed: {
        Platform() {
            return Platform
        },
        platforms(): { id: number; name: string }[] {
            return Object.values(Platform).map((platform, index) => {
                return {id: index, name: platform};
            });
        },
        shouldShowPrefixCheckbox(): boolean {
            return (this.selectedPlatform == Platform.IOS as Platform || this.selectedPlatform == Platform.ANDROID as Platform) === true;
        },
        validatedValuesCount(): Map<string, string> {
            const valueStatuses: Map<string, Map<TranslationStatus, number>> = store.getters.currentProject.valueStatuses()
            const valuesCount: number = store.getters.currentProject.valuesCount()
            const invalidatedValuesCount = new Map<string, string>()
            valueStatuses.forEach((statuses, language) => {
                const invalidatedValues = `${this.$t('translation_status.' + TranslationStatus.VALIDATED)} ${statuses.get(TranslationStatus.VALIDATED) ?? 0}/${valuesCount ?? 0}`
                invalidatedValuesCount.set(language, invalidatedValues)
            });
            return invalidatedValuesCount;
        }
    },
    methods: {
        generateFiles(): void {
            const filesData: FileData[] = this.$service.export.exportDatas(this.selectedPlatform, this.prefixKeysWithGroupName, this.downloadOnlyValidatedValues);
            this.isGenerated = true;

            switch (this.selectedPlatform) {
                case Platform.ANDROID:
                    this.files = filesData.map((file) => {
                        return {
                            name: `strings_${file.language}.xml`,
                            language: file.language,
                            content: file.content
                        };
                    });
                    break;
                case Platform.IOS:
                    this.files = filesData.map((file) => {
                        return {
                            name: file.plural ? `localizable_${file.language}.stringsdict` : `localizable_${file.language}.strings`,
                            language: file.language,
                            content: file.content
                        };
                    });
                    break;
                case Platform.WEB:
                    this.files = filesData.map((file) => {
                        return {
                            name: `${file.language}.json`,
                            language: file.language,
                            content: file.content
                        };
                    });
                    break;
                default:
                    break;
            }
        },
        copyFile(file: TranslationFile) {
            navigator.clipboard.writeText(file.content);
            this.$notify(this.$t("success.copy").toString(), {color: "primary"});
        },
        downloadFile(file: TranslationFile): void {
            Export.downloadFile(file.content, file.name);
        },
        downloadEverything() {
            Export.downloadEverything(this.files, this.selectedPlatform);
        },
        closeDialog() {
            this.$emit("close", false);
        }
    }
});
