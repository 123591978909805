
import {languageNameRules} from "@/data/rules/LanguageRules";
import Vue from "vue";
import {Platform, PlatformExtension, PlatformFileExpected} from "@/data/models/enums/project";
import ImportItem from "@/data/models/ImportItem";
import {importRules, iOSImportRules} from "@/data/rules/ImportRules";
import ImportError from "@/data/models/ImportError";
import Project from "@/data/models/api/Project";

export default Vue.extend({
  name: "language-creation",
  props: {dialogOpened: Boolean},
  data: function () {
    return {
      loading: false,
      languageName: "",
      languageNameRules: languageNameRules(),
      isBlockButton: true,
      fromImport: false,
      selectedPlatform: Platform.ANDROID,
      importItem: new ImportItem("", null) as ImportItem,
      importError: null as ImportError,
      replaceExistingKeys: false,
    };
  },
  watch: {
    dialogOpened(isOpened) {
      if (isOpened) {
        //ON RE-OPENED, RESET DATA
        this.languageName = "";
        this.fromImport = false;
        this.selectedPlatform = Platform.ANDROID;
        this.importItem = new ImportItem("", null) as ImportItem;
        (this.$refs.formCreateLanguage as Vue & { resetValidation: () => any }).resetValidation();
      }
    }
  },
  computed: {
    extensionLimitation(): string {
      return PlatformExtension(this.selectedPlatform);
    },
    platforms(): { id: number; name: string }[] {
      return Object.values(Platform).map((platform, index) => {
        return {id: index, name: platform};
      });
    },
    expectedFormatExplain(): string[] {
      return PlatformFileExpected(this.selectedPlatform);
    },
    multipleFiles(): boolean {
      return this.selectedPlatform === Platform.IOS;
    },
    fileRules(): any {
      return this.selectedPlatform === Platform.IOS ? iOSImportRules() : importRules();
    }
  },
  methods: {
    create() {
      if ((this.$refs.formCreateLanguage as Vue & { validate: () => boolean }).validate() === true) {
        if (this.fromImport) {
          this.createNewLanguageFromImport();
        } else {
          this.createNewLanguage();
        }
      }
    },
    createNewLanguageFromImport() {
      this.loading = true;

      this.$service.languages.createLanguageFromImport(this.$store.getters.currentProject, this.importItem, this.selectedPlatform, this.replaceExistingKeys)
          .then((result: Project) => {
            this.loading = false;
            this.$store.commit("SET_CURRENT_PROJECT", result);
            this.closeLanguageCreation();
          })
          .catch((e) => {
                console.error(e);
                if (e instanceof ImportError) {
                  this.importError = e;
                }

                this.$notify(this.$t("errors.unknown_error").toString(), {color: "red"});
              }
          )
          .finally(() => this.loading = false);
    },
    createNewLanguage() {
      this.loading = true;

      this.$service.languages.createLanguage(this.languageName.toLowerCase())
          .then((result) => {
            this.loading = false;
            this.$store.commit("UPDATE_PROJECT_LANGUAGES", result);

            this.closeLanguageCreation();
          })
          .catch((error) => this.$notify(this.$t(error).toString(), {color: "red"}))
          .finally(() => this.loading = false);
    },
    closeLanguageCreation() {
      this.$emit("close", false);
    }
  }
});

