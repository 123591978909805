
import ProjectSettingsButton from "@/components/molecules/buttons/ProjectSettingsButton.vue";
import Vue from "vue";
import Project from "@/data/models/api/Project";
import DownloadProjectCard from "@/components/molecules/cards/overlay/DownloadProject.vue";
import Language, {LanguageAccess} from "@/data/models/api/Language";

export default Vue.extend({
  name: "detail-header",
  components: {DownloadProjectCard, ProjectSettingsButton},
  props: {
    selectedTargetLanguageId: Number,
    selectedSourceLanguageId: Number
  },
  data() {
    return {
      dialogOpened: false,
      searchValue: "",
      debounceTimeout: null,
      isLoading: false
    };
  },
  destroyed() {
    this.$store.commit("SET_SEARCH_TRANSLATION", "");
  },
  watch: {
    searchValue(value) {
      if (!value || value.length < 3) {
        this.$store.commit("SET_SEARCH_TRANSLATION", "");
        this.isLoading = false;
        clearTimeout(this.debounceTimeout);
        return;
      }

      this.isLoading = true;
      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout);
      }

      this.debounceTimeout = setTimeout(() => {
        this.$store.commit("SET_SEARCH_TRANSLATION", value ?? "");
        this.isLoading = false;
      }, 200);
    }
  },
  computed: {
    currentProject(): Project {
      return this.$store.getters.currentProject;
    },
    sourceLanguages(): { text: string; value: number }[] {
      return this.$store.getters.currentProject.languages.filter((e: Language) => e.access === LanguageAccess.source).map((e: Language) => {
        return {
          text: e.name,
          value: e.id
        };
      });
    },
    shouldShowSourceLanguage(): boolean {
      return this.sourceLanguages.length > 1 && this.selectedTargetLanguageId !== null;
    }
  }
});
