
import Vue from "vue";
import {FirebaseHelper} from "@/data/helpers/firebase";

export default Vue.extend({
  name: "App",
  metaInfo: {
    title: '',
    titleTemplate: 'Lokapp'
  },
  mounted() {
    FirebaseHelper.onAuthStateChanged();
  },
});
