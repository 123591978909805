
import { userEmailRules } from '@/data/rules/UserRules';
import Vue from 'vue'

export default Vue.extend({
    name: "forgot-password",
    created() {
        this.sendEmail = this.email;
    },
    props: {
        email: {
            type: String,
            default: ""
        }
    },
    data() {
        return {
            sendEmail: "",
            emailRules: userEmailRules(),
            loading: false
        }
    },
    methods: {
        closeOverlay() {
            this.$emit("close");
        },
        resetPassword() {
            if ((this.$refs.formSendEmail as any).validate() === true) {
                this.loading = true;
                this.$service.auth.resetPassword(this.sendEmail)
                .then(() => this.$notify(this.$t("success.password_reset").toString(), {color: "primary"}))
                .catch((err) => {
                    if (err === "unknown_email") {
                        this.$notify(this.$t("errors.reset_failed").toString(), {color: "red"});
                    } else {
                        this.$notify(this.$t("errors.unknown_error").toString(), {color: "red"});
                    }
                })
                .finally(() => {
                    this.loading = false;
                    this.closeOverlay();
                });
            }
        }
    }
})
