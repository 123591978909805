
import Vue from "vue";
import Language from "@/data/models/api/Language";

export default Vue.extend({
    name: "language-delete",
    props: {
        language: Language,
        projectId: Number,
        dialogOpened: Boolean
    },
    data() {
        return {
            loading: false
        };
    },
    methods: {
        comebackToLanguageManagement() {
            this.$emit("closeDelete");
        },
        deleteLanguage() {
            this.loading = true;

            this.$service.languages.deleteLanguage(this.language.id, this.projectId)
                .then(() => {
                    this.$store.commit("DELETE_PROJECT_LANGUAGE", this.language);
                    this.comebackToLanguageManagement();
                })
                .catch((error) => this.$notify(this.$t(error).toString(), {color: "red"}))
                .finally(() => this.loading = false);
        }
    }
});
