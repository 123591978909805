
import {keyNameRules} from "@/data/rules/KeyRules";
import Vue from "vue";
import {TranslationItem} from "@/data/models/types/TranslationTypes";
import Key from "@/data/models/api/Key";
import DeleteKey from "@/components/molecules/cards/overlay/DeleteKey.vue";

export default Vue.extend({
  name: "template-item-keys",
  components: {DeleteKey},
  props: {
    item: {},
    projectId: Number,
    canUpdate: Boolean
  },
  data() {
    return {
      dialogOpenedDelete: false,
      updateKey: Object.assign(Key.map({}), (this.item as TranslationItem).key),
      loading: false,
      inputIcon: "",
      keyNameRules: keyNameRules()
    };
  },
  watch: {
    item: {
      handler: function () {
        this.updateKey = Object.assign(Key.map({}), (this.item as TranslationItem).key);
      }
    }
  },
  computed: {
    keyQuantityName(): string {
      return (this.item as TranslationItem)?.quantity ? `[${(this.item as TranslationItem).quantity}]` : "";
    }
  },
  methods: {
    switchQuantity(): Promise<void> {
      this.updateKey.isPlural = !this.updateKey.isPlural;
      return this.saveKey(true);
    },
    saveKey(pluralChanged = false): Promise<void> {
      this.loading = true;

      return this.$service.keys.updateKey(this.updateKey)
          .then((result) => {
            this.loading = false;
            this.inputIcon = "mdi-check";
            this.$emit("saveKey", result);
            setTimeout(() => this.inputIcon = "", 1000);
          })
          .catch((error) => {
            this.$notify(this.$t(error).toString(), {color: "red"});
            if(pluralChanged) {
              //REVERT PLURAL UPDATE
              this.updateKey.isPlural = !this.updateKey.isPlural;
            }
          })
          .finally(() => this.loading = false);
    },
    deletedKey() {
      this.$emit("deleteKey", this.updateKey)
    }
  }
});
