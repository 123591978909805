
import Vue from "vue";
import KeyCreation from "@/components/molecules/cards/overlay/KeyCreation.vue";
import {TranslationItem} from "@/data/models/types/TranslationTypes";
import Group from "@/data/models/api/Group";

export default Vue.extend({
    name: 'template-group-footer',
    components: {KeyCreation},
    props: {
      isOpen: Boolean,
      groupId: Number,
      items: []
    },
    data() {
        return {
          isOpenCreation: false,
        }
    },
    computed: {
      currGroup(): Group {
        return (this.items as TranslationItem[])[0].group;
      }
    },
    methods: {
        close() {
          this.isOpenCreation = false;
        }
    }
});
