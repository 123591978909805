
import {keyNameRules} from "@/data/rules/KeyRules";
import {groupNameRules} from "@/data/rules/GroupRules";
import Vue from "vue";
import Key from "@/data/models/api/Key";
import Project from "@/data/models/api/Project";
import Group from "@/data/models/api/Group";

export default Vue.extend({
  name: "key-creation",
  props: {
    selectedGroup: Group,
    isOpen: Boolean
  },
  created() {
    this.loadData();
  },
  data: function () {
    const emptyGroup = Group.empty(this.$t("key_creation.new_group").toString());

    return {
      //DATA
      groups: [emptyGroup],
      currentGroup: emptyGroup,
      newKey: Key.empty(),
      groupName: "",

      //RULES
      keyNameRules: keyNameRules(),
      groupNameRules: groupNameRules(),

      //UI
      loading: false,
      isBlockButton: true,
      snackbarError: false,
      errorText: ""
    };
  },
  computed: {
    currentProject(): Project {
      return this.$store.state.currentProject;
    }
  },
  watch: {
    "newKey.name": function () {
      this.newKey.name = this.newKey.name.replaceAll(" ", "_");
    }
  },
  methods: {
    loadData(): void {
      this.currentProject.groups.forEach((group) => this.groups.push(group));
      this.currentGroup = this.selectedGroup ?? this.groups[0];
    },
    closeKeyCreation() {
      this.$emit("closeCreation", false);
    },
    async createKeyWithGroup(): Promise<void> {
      if ((this.$refs.formCreateKey as Vue & { validate: () => boolean }).validate() === true) {
        this.loading = true;

        if (this.currentGroup.isNewGroup) {
          this.currentGroup.name = this.groupName;
        }

        let data: { group: Group | null; key: Key };
        try {
          data = await this.$service.keys.createKeyWithGroup(this.currentGroup, this.newKey);
          this.$store.commit("ADD_PROJECT_KEY", data);
          this.closeKeyCreation();
        } catch (e: any) {
          console.error(e);
          this.$notify(this.$t(e).toString(), {color: "red"});
        }

        this.loading = false;
      }
    }
  }
});
