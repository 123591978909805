
import {Vue} from "vue-property-decorator";
import MyProjects from "@/components/molecules/dashboard/MyProjects.vue";
import HeaderBanner from "@/components/molecules/dashboard/HeaderWithBanner.vue";
import LeftNavBar from "@/components/molecules/dashboard/LeftNavBar.vue";
import Project from "@/data/models/api/Project.js";

export default Vue.extend({
  components: {
    MyProjects,
    HeaderBanner,
    LeftNavBar
  },
  metaInfo() {
    return {
      title: "Lokapp",
      titleTemplate: null,
    };
  },
  data() {
    return {
      projects: [] as Project[],
      loading: false
    };
  },
  created() {
    this.refreshProjectList();
  },
  methods: {
    refreshProjectList() {
      this.loading = true;

      this.$service.projects.getProjects()
          .then((projects: Project[]) => {
            this.projects = projects;
          }).finally(() => this.loading = false);
    },
  }
});
