
import { Vue } from "vue-property-decorator";
import UpBar from "@/components/molecules/login/UpBar.vue";
import LoginForm from "@/components/molecules/login/LoginForm.vue";
import Illustration from "@/components/molecules/login/Illustration.vue";

export default Vue.extend({
  name: "LoginPage",
  components: { UpBar, LoginForm, Illustration,},
  data() {
    return {
      pageName: "Lokapp"
    }
  }
})
