
import Vue from 'vue'

export default Vue.extend({
    name: "leave-project",
    props: {projectId: Number, dialogOpened: Boolean},
    data() {
        return {
            loading: false
        }
    },
    methods: {
        closeOverlay() {
            this.$emit("close");
        },
        leaveProject() {
            this.loading = true;
            this.$service.projects.leaveProject(this.projectId)
            .then(() => {
                this.$router.push("/dashboard");
            }).catch(() => {
                this.$notify(this.$t("errors.unknown_error").toString(), {color: "red"});
            }).finally(() => {
                this.closeOverlay();
                this.loading = false;
            });
        }
    }
})
