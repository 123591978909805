
import ProjectUser from "@/data/models/api/ProjectUser";
import { getRoleEnum, Role} from "@/data/models/roles/role.enum";
import Vue from "vue";
import InvitationCreation from "@/components/molecules/cards/overlay/InvitationCreation.vue";
import UserDelete from "@/components/molecules/cards/overlay/UserDelete.vue";
import Language from "@/data/models/api/Language";

export default Vue.extend({
    name: "user-management",
    components: {UserDelete, InvitationCreation},
    props: {projectId: Number, dialogOpened: Boolean},
    data() {
        return {
            users: [] as ProjectUser[],
            invitations: [],
            userToDelete: null,
            openDialogInvitation: false,
            openDialogDelete: false
        };
    },
    watch: {
        dialogOpened: {
            immediate: true,
            handler: function (isOpened) {
                if (isOpened) {
                    //ON RE-OPENED, RESET DATA
                    this.refresh();
                }
            }
        }
    },
    computed: {
      me(): ProjectUser {
        return this.$store.getters.appUser;
      },
      roles(): any[] {
        return  Object.values(Role).map((role: string) => {
          return {text: this.$t(`users_manage.role_${getRoleEnum(role)}`).toString(), value: role};
        });
      },
      isInvitationDisabled(): boolean {
        return this.me.roleAbility ? !(this.me.roleAbility.canWriteInvitation): false;
      },
      canCreateInvitation(): boolean {
        return this.me.role ? this.me.roleAbility.canWriteInvitation : false;
      },
      languages(): any[] {
        return this.$store.getters.currentProject.languages.map((language: any) => {
          return {
            text: language.name,
            value: language.id
          }
        });
      },
    },
    methods: {
        deleteInvitation(invitation: ProjectUser) {
            this.$service.invitations.deleteInvitation(invitation.invitationId)
                .then(() => this.refresh())
                .catch((error) => this.$notify(this.$t(error).toString(), {color: "red"}));
        },
        isUserUpdateRoleDisabled(user: ProjectUser): boolean {
            return (!this.me.roleAbility.canWriteUser ? true : user.role === Role.OWNER) || user.userId === this.me.userId;
        },
        closeManageUsers() {
            this.$emit("close");
        },
        getEveryUsersOfProject() {
            this.$service.projects.getUsersOfProject(this.projectId)
                .then((users) => {
                    users.forEach((user) => {
                        if (!user.pending) {
                            this.users.push(user);
                        } else {
                            this.invitations.push(user);
                        }
                    });

                    this.users.sort((a: ProjectUser, b: ProjectUser) => {
                        if (a.email < b.email) {
                            return -1;
                        } else if (a.email > b.email) {
                            return 1;
                        }
                        return 0;
                    });

                    this.invitations.sort((a: ProjectUser, b: ProjectUser) => {
                        if (a.email < b.email) {
                            return -1;
                        } else if (a.email > b.email) {
                            return 1;
                        }
                        return 0;
                    });
                })
                .catch(() => this.$notify(this.$t("errors.unknown_error").toString(), {color: "red"}));
        },
        refresh() {
            this.users = [];
            this.invitations = [];

            this.getEveryUsersOfProject();
        },
        updateRole(user: ProjectUser) {
            const languages: Language[] = this.$store.getters.currentProject.languages;
            const sourceLanguages = languages.filter((language) => user.sourceLanguagesIds.includes(language.id));
            const targetLanguages = languages.filter((language) => user.targetLanguagesIds.includes(language.id));
            this.$service.projects.updateRoleOfUser(this.projectId, user.userId, user.role, sourceLanguages, targetLanguages)
                .then((userUpdated) => {
                    user = userUpdated as ProjectUser;
                    if (this.me.role === Role.OWNER && user.role === Role.OWNER) {
                        this.refresh();
                    }
                })
                .catch((error) => this.$notify(this.$t(error).toString(), {color: "red"}));
        },
        deleteUser(user: ProjectUser) {
            this.userToDelete = user;
            this.openDialogDelete = true;
        },
        closeDelete() {
          this.openDialogDelete = false;
          this.refresh();
        },
      canShowSourceAndTargetLanguages(user: ProjectUser): boolean {
        return user.role === Role.TRANSLATOR || user.role === Role.REVIEWER;
      }
    }
});
