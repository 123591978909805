
import Vue from "vue";
import ProjectUser from "@/data/models/api/ProjectUser.js";
import {Locale} from "@/data/models/locales/locales";

export default Vue.extend({
  name: "profile-manager",
  props: {dialogOpened: Boolean},
  data() {
    return {
      user: null as ProjectUser,
      username: "",
      loading: false,
      locales: Locale.getLocales(),
      selectedLocale: null
    };
  },
  created() {
    if (localStorage.locale) {
      this.$i18n.locale = localStorage.locale;
    }
    this.selectedLocale = this.$i18n.locale;
  },
  watch: {
    dialogOpened: {
      immediate: true,
      handler: function (isOpen) {
        if (isOpen) {
          this.user = this.$store.getters.appUser;
          this.username = this.user?.username ?? "";
        }
      }
    }
  },
  methods: {
    validateProfile() {
      if (this.username !== this.user.username) {
        if (this.username.length === 0) this.username = null;

        this.loading = true;
        this.$service.user.updateProfile(this.username)
            .then((user) => {
              this.$store.commit('UPDATE_APP_USER', user);
              this.closeOverlay();
            })
            .catch(() => this.$notify(this.$t("errors.update_user").toString(), {color: "red"}))
            .finally(() => this.loading = false);
      } else {
        this.closeOverlay();
      }
    },
    resetPassword() {
      this.$service.auth.resetPassword(this.user.email)
          .then(() => {
            this.$notify(this.$t("success.password_reset").toString(),{color: "primary"});
            this.$service.auth.logOut().then(() => this.$router.push("/login"))
          })
          .catch(() => this.$notify(this.$t("errors.reset_password").toString(), {color: "red"}));
    },
    handleLocaleSelection() {
      this.switchLanguage(this.selectedLocale);
    },
    switchLanguage(language: string) {
      if (this.$i18n.locale !== language) {
        this.$i18n.locale = language;
        localStorage.locale = language;
      }
    },
    logMeOut() {
      this.$service.auth.logOut()
          .then(() => this.$router.push("/login"));
    },
    closeOverlay() {
      this.$emit("close");
    }
  }
});
