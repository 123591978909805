
import ProjectCard from "../cards/ProjectCard.vue";
import Vue from "vue";
import Project from "@/data/models/api/Project";

export default Vue.extend({
  name: "my-projects",
  components: {ProjectCard},
  props: {projects: [], loading: Boolean},
  data() {
    return {
      timerUpdate: 0,
      totalSecondes: 0,
      timer: null,
    };
  },
  watch : {
    projects() {
      this.updateMyTimer();
    }
  },
  computed: {
    searchProjectValue(): string {
      return this.$store.state.searchProject;
    },
    displayedProjects(): Project[] {
      return (this.projects as Project[]).filter((project) => project.name.toUpperCase().includes(this.searchProjectValue.toUpperCase()));
    }
  },
  methods: {
    updateMyTimer() {
      this.totalSecondes = 0;
      this.timerUpdate = 0;
      clearInterval(this.timer);
      this.timer = setInterval(() => this.timerUpdate = Math.floor(++this.totalSecondes / 60), 1000);
    }
  }
});
