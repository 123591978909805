
import {firstChar} from "@/data/helpers/stringFormatting";
import ProjectSettingsButton from "@/components/molecules/buttons/ProjectSettingsButton.vue";
import Vue from "vue";
import Project from "@/data/models/api/Project";
import ProjectManagement from "@/components/molecules/cards/overlay/ProjectManagement.vue";

export default Vue.extend(
    {
      name: "project-card",
      components: {ProjectManagement, ProjectSettingsButton},
      props: {project: Project},
      data() {
        return {
          //  USED TO REFRESH VALUES OF PROJECT WHEN PARAMETERS UPDATED FROM ProjectSettingsButton
          currProject: this.project,
          dialogOpened: false
        };
      },
      computed: {
        firstCharProjectName(): string {
          return firstChar(this.currProject?.name);
        }
      },
      methods: {
        openProjectView() {
          this.$store.commit("SET_CURRENT_PROJECT", this.project);
          this.$router.push(`/projects/${this.project.id}`);
        },
        openCreateProject() {
          this.dialogOpened = true;
        },
        updateProject(project: Project) {
          //WHEN PROJECT UPDATED FROM ProjectSettingsButton > ProjectManagement
          this.currProject = Project.map(project);
        }
      }
    });
