
import Language from "@/data/models/api/Language";
import Vue from "vue";
import LanguageDelete from "@/components/molecules/cards/overlay/LanguageDelete.vue";

export default Vue.extend({
    name: "language-management",
    components: {LanguageDelete},
    props: {projectId: Number, dialogOpened: Boolean},
    data() {
        return {
            dialogOpenedDelete: false,
            languageToDelete: null as Language
        };
    },
    computed: {
      languages(): Language[] {
        return this.$store.getters.currentProject.languages;
      }
    },
    methods: {
        closeOverlay(): void {
            this.$emit("close");
        },
        deleteLanguage(language: Language): void {
            this.dialogOpenedDelete = true;
            this.languageToDelete = language;
        }
    }
});
