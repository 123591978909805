
import Vue from "vue";
import ProfileManager from "@/components/molecules/cards/overlay/PreferencesManagement.vue";

export default Vue.extend({
  name: "left-nav-bar",
  components: {ProfileManager},
  data() {
    return {
      preferencesModalOpened: false
    };
  },
  methods: {
    redirectToDashboard() {
      this.$router.push("/dashboard");
    }
  }
});
