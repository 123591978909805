
import Vue from 'vue'
import Project from "@/data/models/api/Project";

export default Vue.extend({
    name: "delete-project",
    props: {project: Project, dialogOpened: Boolean, fromDetails: Boolean},
    data() {
        return {
            loading: false,
        }
    },
    methods: {
        closeOverlay(): void {
            this.$emit("close");
        },
        deleteProject() {
            this.loading = true;
            this.$service.projects.deleteProject(this.project.id)
            .then(() => {
                if(this.fromDetails) {
                  this.$router.push("/dashboard");
                } else {
                  this.$emit("projectDeleted");
                }

                this.closeOverlay();
            })
            .catch(() => this.$notify(this.$t("errors.project_deleted").toString(),{color: "red"}))
            .finally(() => this.loading = false);
        }
    }
})
