
import Vue from "vue";

export default Vue.extend({
    name: 'action-button',
    props: {
        loading: Boolean,
        block: Boolean,
        handler: Function,
        text: String,
        addIcon: Boolean
    }
})
