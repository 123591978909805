
import {Vue} from "vue-property-decorator";
import LeftNavBar from "@/components/molecules/dashboard/LeftNavBar.vue";
import LanguagesGroup from "@/components/molecules/project/LanguagesGroup.vue";
import ContentDetails from "@/components/molecules/project/ContentDetails.vue";
import {MetaInfo} from "vue-meta";
import Project from "@/data/models/api/Project";

export default Vue.extend({
  name: "DetailProject",
  metaInfo(): MetaInfo {
    return {
      title: this.currentProject?.name ?? "",
      titleTemplate: `Lokapp - %s`
    };
  },
  components: {
    LeftNavBar,
    LanguagesGroup,
    ContentDetails
  },
  data() {
    return {
      loading: true
    };
  },
  created() {
    this.loadProject();
  },
  computed: {
    currentProject(): Project {
      return this.$store.state.currentProject;
    }
  },
  methods: {
    loadProject() {
      this.loading = true;

      this.$service.projects.getEntireProjectById(parseInt(this.$route.params.project_id))
          .then(async (project: Project) => {
            this.$store.commit("SET_CURRENT_PROJECT", project);
            await this.updateMyRole(project.id);
            this.loading = false;
          })
          .catch(() => {
            this.$notify(this.$t("errors.retrieve_project").toString(), {color: "red"});
            this.$store.commit("SET_CURRENT_PROJECT", null);
            this.backToDashboard();
          });
    },
    async updateMyRole(projectId: number): Promise<void> {
      return this.$service.user.getMyselfInProject(projectId).then((user) => this.$store.commit("SET_APP_USER", user));
    },
    backToDashboard() {
      this.$router.push({path: "/dashboard"});
    }
  }
});
