
import Vue from "vue";
import LanguageCreation from "@/components/molecules/cards/overlay/LanguageCreation.vue";
import Language, {LanguageAccess} from "@/data/models/api/Language";
import Project from "@/data/models/api/Project";

export default Vue.extend({
    name: "languages-group",
    components: {LanguageCreation},
    data() {
        return {
            actualTab: 0,
            dialogOpened: false
        };
    },
    watch: {
        actualTab() {this.setActualLanguage()}
    },
    computed: {
      canWriteLanguage(): boolean {
        return this.$store.getters.appUser.roleAbility ? this.$store.getters.appUser.roleAbility.canWriteLanguage : false;
      },
      languages(): Language[] {
        return (this.$store.getters.currentProject as Project).languages.filter(language => language.access !== LanguageAccess.source);
      }
    },
    methods: {
        setActualLanguage() {
            this.$store.commit("SET_ACTUAL_LANGUAGE", this.actualTab > 0 ? this.languages[this.actualTab - 1].id : null);
        },
    },
});
